import React, { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import BookADemoButton from './BookADemo';
import data from '../data.json';

const FullscreenMobileMenu = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [openFeatures, setOpenFeatures] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleClickFeatures = () => {
    setOpenFeatures(!openFeatures);
  };

  const list = () => (
    <div role='presentation' onKeyDown={toggleDrawer(false)}>
      <List>
        <ListItem
          className='text-4xl text-gray-600'
          onClick={handleClickFeatures}
        >
          Features
          {openFeatures ? (
            <ExpandLess fontSize='large' />
          ) : (
            <ExpandMore fontSize='large' />
          )}
        </ListItem>
        <Collapse in={openFeatures} timeout='auto' unmountOnExit>
          <List component='div' disablePadding>
            {data.pages.features.map(({ title, href }) => (
              <ListItem
                key={title}
                component='a'
                className='!text-gray-600 !px-3 !text-lg'
                href={href}
                onClick={toggleDrawer(false)} // Close the drawer when a feature is clicked
              >
                <ListItemButton style={{ padding: '0.125rem 1rem 0.125rem 1.25em'}}>
                  {title}
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Collapse>
        <ListItem
          key={`about`}
          component='a'
          href={'/about-abio'}
          className='text-4xl text-gray-600'
          onClick={toggleDrawer(false)} // Close the drawer when link is clicked
        >
          About Abio
        </ListItem>
        <div className='flex justify-center my-5'>
          <BookADemoButton
            variant='no-icon'
            onClick={() => {
              toggleDrawer(false)(); // Close the drawer
            }}
          />
        </div>
      </List>
    </div>
  );

  return (
    <div>
      <>
        <IconButton
          edge='start'
          color='inherit'
          aria-label='menu'
          onClick={toggleDrawer(true)}
          className='float-right'
        >
          <MenuIcon />
        </IconButton>
        <Drawer anchor='top' open={drawerOpen} onClose={toggleDrawer(false)}>
          {list()}
        </Drawer>
      </>
    </div>
  );
};

export default FullscreenMobileMenu;
