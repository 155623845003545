import { Unstable_Grid2 as Grid } from '@mui/material';
import Item from '@mui/material/Grid';
import { imageMap } from '../Assets/imageAssets';
import { safeRenderTextWithLinks } from '../utils/safeRenderTextWithLinks';
import useMediaQuery from '@mui/material/useMediaQuery';

const IconList = ({ headline, description, list }) => {
  const matches = useMediaQuery('(min-width:900px)'); // 'md' breakpoint in MUI
  const firstHalfLength = Math.ceil(list.length / 2);
  const firstHalf = list.slice(0, firstHalfLength);
  const secondHalf = list.slice(firstHalfLength);

  const renderListItems = (items, index) => (
    <ul className={`icon-bullet list-${index}`} style={{ listStyle: 'none' }}>
      {items.map(({ text, icon, alt }, itemIndex) => (
        <li
          key={itemIndex}
          className='flex items-center'
          style={itemIndex !== 0 ? { marginTop: '32px' } : {}}
        >
          <img src={imageMap[icon]} alt={alt} className='mr-8' />
          <span className='text-gray-600'>{text}</span>
        </li>
      ))}
    </ul>
  );

  return (
    <div className='icon-list-container'>
      <Grid container className='bg-white'>
        {(headline || description) && (
          <>
            <Grid
              xs={10}
              sm={10}
              md={7}
              lg={5}
              xsOffset={1}
              className='flex items-center mb-10'
            >
              <Item>
                {headline && (
                  <h2 className='abio-feature-title text-gray-800'>
                    {headline}
                  </h2>
                )}
                <p className='abio-paragraph text-gray-600'>
                  {safeRenderTextWithLinks(description)}
                </p>
              </Item>
            </Grid>
            <Grid xs={0} sm={0} md={4} lg={6} />
          </>
        )}
        <Grid xs={10} xsOffset={1} md={5} className='flex items-center'>
          <Item>
            <div
              className='abio-paragraph text-gray-600'
              style={{ width: matches ? '90%' : '100%' }}
            >
              {renderListItems(firstHalf, 1)}
            </div>
          </Item>
        </Grid>
        <Grid
          xs={10}
          xsOffset={1}
          mdOffset={0}
          md={5}
          className='flex items-center'
        >
          <Item>
            <div className='abio-paragraph text-gray-600'>
              {renderListItems(secondHalf, 2)}
            </div>
          </Item>
        </Grid>
      </Grid>
    </div>
  );
};

export default IconList;
