import LearnMore from './LearnMore';
import cardCloudAccountingImage from '../Assets/card-cloud-accounting.png';
import { imageMap } from '../Assets/imageAssets';
import './FeatureCard.scss';

function FeatureCard({ headline, description, href, imgSrc, alt }) {
  return (
    <div className='feature-card bg-blue-100 text-gray-500 rounded-30 h-full relative'>
      <div className='feature-card__image rounded-t-30 overflow-hidden'>
        <img
          decoding='async'
          src={imageMap[imgSrc] || cardCloudAccountingImage}
          alt={alt}
          className='w-full'
        />
      </div>
      <div className='feature-card__title abio-title'>{headline}</div>
      <div className='feature-card__description abio-paragraph'>
        {description}
      </div>
      <div className='feature-card__link'>
        <LearnMore href={href} colorMode='blue' />
      </div>
    </div>
  );
}

export default FeatureCard;
