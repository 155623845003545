const scrollToContactFormSection = () => {
  const element = document.getElementById('contact-form-section');
  if (element) {
    const rect = element.getBoundingClientRect();
    const offset = rect.top + window.pageYOffset - 80;
    window.scrollTo({
      top: offset,
      behavior: 'smooth'
    });
  }
};

export default scrollToContactFormSection;
