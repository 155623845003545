import React from 'react';
import {
  Unstable_Grid2 as Grid,
  Menu,
  MenuItem,
  Link,
  Hidden
} from '@mui/material';
import Item from '@mui/material/Grid'; // Importing Item from '@mui/material/Grid'
import { ExpandMore as ExpandMoreIcon } from '@mui/icons-material';
import BookADemoButton from './BookADemo';
import logo from '../Assets/Abio_Logo_NoTagline_Blue_250w.png';
import data from '../data.json';
import FullscreenMobileMenu from './FullScreenMobileMenu';

function Header() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  let closeMenuTimeout = null;

  const handleMenuOpen = (event) => {
    clearTimeout(closeMenuTimeout); // clear any existing timeout
    setAnchorEl(event.currentTarget); // open menu
  };

  const handleButtonMenuClose = () => {
    closeMenuTimeout = setTimeout(() => {
      setAnchorEl(null);
    }, 1000); // set a delay for closing the menu
  };

  const cancelMenuClose = () => {
    clearTimeout(closeMenuTimeout); // clear timeout if mouse enters menu
  };

  const handleMenuListLeave = () => {
    setAnchorEl(null); // close menu immediately
  };

  return (
    <Grid
      container
      className='h-20 items-center sticky top-0 z-30 bg-white shadow-md'
    >
      <Grid xs={1} xsOffset={1}>
        <Item component='a' href='/'>
          <img className='h-7 max-w-fit' src={logo} alt='Abio logo' />
        </Item>
      </Grid>

      <Hidden mdDown>
        <Grid md={2} mdOffset={4} xl={1} xlOffset={6}>
          <Item className='flex items-end'>
            <div
              onMouseEnter={handleMenuOpen}
              onMouseLeave={handleButtonMenuClose}
              className={`${
                anchorEl ? 'text-orange-500' : 'text-gray-600'
              } text-lg`}
            >
              Features
              <ExpandMoreIcon
                className='ml-1'
                style={{
                  transition: 'transform 0.3s ease',
                  transform: anchorEl ? 'rotate(180deg)' : 'rotate(0deg)' // Rotate based on menu state
                }}
              />
            </div>
            <Menu
              id='menu-appbar'
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left'
              }}
              keepMounted
              PaperProps={{
                sx: {
                  borderRadius: '8px',
                  marginTop: '12px',
                  marginLeft: '-12px'
                }
              }}
              open={Boolean(anchorEl)}
              MenuListProps={{
                onMouseEnter: cancelMenuClose,
                onMouseLeave: handleMenuListLeave // close immediately when mouse leaves menu
              }}
              disableAutoFocusItem // prevents the first item from auto-receiving focus
            >
              {data.pages.features.map(({ title, href }) => (
                <MenuItem
                  key={`menu-item-${href}`}
                  component={Link}
                  className='!text-gray-600 !px-3 !text-lg !leading-10'
                  href={href}
                >
                  {title}
                </MenuItem>
              ))}
            </Menu>
          </Item>
        </Grid>
        <Grid md={2} xl={1}>
          <Item>
            <a
              className='text-gray-600 hover:text-orange-500 text-lg'
              href='/about-abio'
            >
              About Abio
            </a>
          </Item>
        </Grid>
        <Grid md={2}>
          <Item className='-ml-6'>
            <BookADemoButton variant='no-icon' />
          </Item>
        </Grid>
      </Hidden>

      {/* Hamburger menu */}
      <Hidden mdUp>
        <Grid xs={1} xsOffset={8}>
          <Item>
            <FullscreenMobileMenu />
          </Item>
        </Grid>
      </Hidden>
    </Grid>
  );
}

export default Header;
