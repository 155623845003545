import { Unstable_Grid2 as Grid, useMediaQuery } from '@mui/material';
import FeatureCard from './FeatureCard.jsx';
import data from '../data.json';
import Flickity from 'react-flickity-component';
import './flickityStyles.scss';

import 'flickity/css/flickity.css';

const Carousel = ({ href }) => {
  const isXLargeScreen = useMediaQuery('(min-width:1536px)');
  const isLargeScreen = useMediaQuery('(min-width:1200px)');
  const isMediumScreen = useMediaQuery('(min-width:900px)');
  const isSmallScreen = useMediaQuery('(min-width:600px)');

  let cellWidth;

  if (isXLargeScreen) {
    cellWidth = 'calc((300%/10) - 1rem)'; // 3 columns
  } else if (isLargeScreen) {
    cellWidth = 'calc((400%/10) - 1rem)'; // 4 columns
  } else if (isMediumScreen) {
    cellWidth = 'calc((500%/10) - 1rem)'; // 5 columns
  } else if (isSmallScreen) {
    cellWidth = 'calc((700%/10) - 1rem)'; // 7 columns
  } else {
    cellWidth = '100%'; // 10 columns
  }

  return (
    <Grid
      container
      className='abio-carousel carousel-container bg-blue-600 rounded-t-30 text-white'
    >
      <Grid xs={10} sm={10} md={10} lg={10} xsOffset={1}>
        <p className='abio-title carousel-title-spacer'>More features</p>
        <Flickity
          className='feature-carousel flickity-enabled is-draggable'
          options={{
            cellAlign: 'left'
          }}
        >
          {data.pages.features
            .slice()
            .reverse()
            .map((item, index) => {
              if (item.href !== href) {
                return (
                  <div
                    className='carousel-cell lg:h-626 sm:h-540 h-560 ml-4'
                    style={{ width: cellWidth, maxWidth: '610px' }}
                    key={`feature-card-${item.href}`}
                  >
                    <FeatureCard {...item} />
                  </div>
                );
              }
              return null;
            })}
        </Flickity>
      </Grid>
    </Grid>
  );
};

export default Carousel;
