import { Unstable_Grid2 as Grid } from '@mui/material';
import Item from '@mui/material/Grid';
import LearnMore from './LearnMore';
import placeholder3 from '../Assets/make-better-decisions_690.png';
import { safeRenderTextWithLinks } from '../utils/safeRenderTextWithLinks';

const FeatureRight = ({ headline, description, href, imgSrc, alt }) => {
  return (
    <div className='feature-container'>
      <Grid
        container
        className='bg-white'
        style={{ flexWrap: 'wrap-reverse' }} // so that text and image order match feature left component
      >
        <Grid
          xs={10}
          sm={10}
          md={5}
          lg={5}
          xsOffset={1}
          className='flex justify-end'
        >
          <img
            src={imgSrc || placeholder3}
            alt={alt}
            className='feature-image w-full object-contain'
          />
        </Grid>
        <Grid
          xs={10}
          sm={10}
          md={4}
          lg={4}
          xsOffset={1}
          className='flex items-center'
        >
          <Item>
            {headline && (
              <h2 className='abio-feature-title text-gray-800'>{headline}</h2>
            )}
            <p className='abio-paragraph text-gray-600'>
              {safeRenderTextWithLinks(description)}
            </p>

            {href && <LearnMore href={href} />}
          </Item>
        </Grid>
      </Grid>
    </div>
  );
};

export default FeatureRight;
