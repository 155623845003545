import { Unstable_Grid2 as Grid } from '@mui/material';
import Item from '@mui/material/Grid';
import builtBetterImage from '../Assets/built-better.png';

const Callout = ({ text, imgSrc, alt, fontSize = 'large', theme = 'dark' }) => {
  const fontSizeClass =
    fontSize === 'small' ? 'abio-title' : 'abio-feature-title';
  const colorClasses =
    theme === 'dark' ? 'bg-slate text-white' : 'bg-white text-gray-700';

  return (
    <div className='callout-container'>
      <Grid container className={colorClasses}>
        <Grid
          xs={10}
          sm={10}
          md={6}
          lg={6}
          xsOffset={1}
          className='flex items-center'
        >
          <Item>
            <p className={`${fontSizeClass}`}>{text}</p>
          </Item>
        </Grid>
        <Grid
          xs={10}
          sm={5}
          md={4}
          lg={3}
          xsOffset={1}
          smOffset={5}
          mdOffset={1}
          lgOffset={2}
          className={'md:relative xs:flex'}
        >
          <img
            src={imgSrc || builtBetterImage}
            alt={alt}
            className='md:absolute right-12 w-full xs:object-contain'
            style={{
              top: 'calc(33% + (1792px - 100vw) * 0.1)'
            }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Callout;
