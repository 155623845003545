import { Unstable_Grid2 as Grid } from '@mui/material';
import Item from '@mui/material/Grid';
import placeholder from '../Assets/leadspace-home-1.png';
import BookADemoButton from './BookADemo';
import useMediaQuery from '@mui/material/useMediaQuery';
import classNames from 'classnames';

const Leadspace = ({
  feature,
  headline,
  description,
  imgSrc,
  alt,
  scale,
  href
}) => {
  const matches = useMediaQuery('(min-width:600px)'); // 'sm' breakpoint in MUI
  const imageClasses = scale
    ? classNames(
        'sm:absolute',
        'bottom-0',
        'left-0',
        'transform',
        'origin-bottom-left'
      )
    : classNames('w-full');
  const bgMapping = {
    // light blue
    '/': 'bg-blue-600',
    '/about-abio': 'bg-blue-600',
    '/construction-accounts-payable': 'bg-blue-600',
    '/subcontractors-purchase-orders': 'bg-blue-600',

    // dark blue
    '/construction-finance': 'bg-blue-700',
    '/mobile-timesheet-software': 'bg-blue-700',
    '/cloud-based-accounting-software': 'bg-blue-700',

    // light green
    '/job-costing': 'bg-green-400',
    '/reporting-analytics': 'bg-green-400',

    // dark green
    '/canadian-payroll': 'bg-green-700',
    '/construction-invoicing': 'bg-green-700',
    '/document-management-for-construction': 'bg-green-700'
  };
  let bg = bgMapping[href] || 'bg-blue-600'; // Default to 'bg-blue-600' if `href` is not found in the mapping
  const leadspaceClasses = classNames(bg, 'text-white');

  return (
    <div className='leadspace-container'>
      <Grid
        container
        className={leadspaceClasses}
        style={matches ? { height: '87vh' } : {}}
      >
        <Grid
          xs={10}
          sm={5}
          md={5}
          lg={href === '/' ? 4 : 5} // fewer columns on homepage
          xsOffset={1}
          className='flex items-center'
        >
          <Item
            className='leadspace-text-container'
            style={matches ? { width: '85%' } : {}}
          >
            {feature && <p className='abio-sup-title'>{feature}</p>}
            <h1 className='abio-leadspace-title'>{headline}</h1>
            <p className='abio-paragraph'>{description}</p>
            <div className='mt-10'>
              <BookADemoButton variant='with-icon' />
            </div>
          </Item>
        </Grid>
        <Grid
          xs={12}
          sm={6}
          md={6}
          lg={6}
          lgOffset={href === '/' ? 1 : 0} // adjust for homepage
          className='relative overflow-hidden h-full'
        >
          <img
            className={imageClasses}
            src={imgSrc || placeholder}
            alt={
              alt ||
              'Dashboard of construction accounting software showing key financial metrics like total spend and revenue, with a line graph and a photo of construction workers in the background.'
            }
            style={{ transform: matches ? `scale(${scale / 100})` : 'none' }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Leadspace;
