import { useState } from 'react';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import scrollToContactFormSection from '../utils/scrollToContactFormSection';

const getButtonStyles = (variant, color, isHovered) => {
  const styles = {
    orange: {
      'no-icon': {
        bg: isHovered ? 'bg-orange-500' : 'bg-orange-100',
        text: isHovered ? 'text-orange-100' : 'text-orange-500'
      },
      'with-icon': {
        bg: isHovered ? 'bg-white' : 'bg-orange-500',
        text: isHovered ? 'text-orange-500' : 'text-white'
      }
    },
    green: {
      'no-icon': {
        bg: isHovered ? 'bg-green-600' : 'bg-green-100',
        text: 'text-white'
      },
      'with-icon': {
        bg: isHovered ? 'bg-green-600' : 'bg-green-100',
        text: 'text-white'
      }
    }
  };
  return styles[color][variant] || {};
};

const BookADemoButton = ({
  variant = 'no-icon',
  color = 'orange',
  type = 'button',
  onClick
}) => {
  const [isHovered, setHovered] = useState(false);
  const toggleHover = () => setHovered(!isHovered);

  const handleClick = (e) => {
    scrollToContactFormSection();
    if (onClick) onClick();
  };

  let paddingX, paddingY;
  const { bg, text } = getButtonStyles(variant, color, isHovered);

  if (color === 'orange') {
    if (variant === 'no-icon') {
      paddingX = 'px-6';
      paddingY = 'py-2';
    } else if (variant === 'with-icon') {
      paddingX = 'pl-6 pr-3';
      paddingY = 'py-3';
    }
  } else if (color === 'green') {
    paddingX = 'px-12';
    paddingY = 'py-4';
  }

  const fontWeight = variant === 'with-icon' ? 'font-semibold' : 'font-normal';

  return (
    <button
      type={type}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      onClick={(e) => handleClick(e)}
      className={`abio-button flex items-center ${bg} ${text} ${fontWeight} ${paddingX} ${paddingY} rounded-full cursor-pointer`}
    >
      Book&nbsp;a&nbsp;Demo
      {variant === 'with-icon' && (
        <AccountCircleIcon
          style={{ fontSize: 28, color: text, marginLeft: '12px' }}
        />
      )}
    </button>
  );
};

export default BookADemoButton;
