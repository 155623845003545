import allInOneImage from './all-in-one_690-1.png';
import realNumbersImage from './real-numbers_690-1.png';
import peakEfficiencyImage from './peak-efficiency_690-1.png';
import automateTediousTasksImage from './automate-tedious-tasks.png';
import completeControlOfYourConstructionBusinessImage from './complete-control-of-your-construction-finance-1.png';
import keepCashFlowingImage from './keep-cash-flowing.png';
import boostYourEfficiencyImage from './boost-your-efficiency.png';
import stressFreeConstructionInvoicingImage from './stress-free-construction-invoicing.png';
import receivablesReportingAtAGlanceImage from './receivables-reporting-at-a-glance.png';
import optimizeYourEfficiencyImage from './optimize-your-efficiency.png';
import group124Image from './Group-124.png';
import flexibleAccountsPayableImage from './flexible-accounts-payable.png';
import dataYouCanRelyOnImage from './data-you-can-rely-on.png';
import subcontractorCalloutV2Image from './subcontractor-callout-v2-1.png';
import subcontractorImage from './subcontractor-img.png';
import subcontractorAccountingSimplifiedImage from './subcontractor-accounting-simplified.png';
import group120Image from './Group-120.png';
import eliminateTimesheetUncertaintyImage from './eliminate-timesheet-uncertainty.png';
import monitorYourLabourCostsImage from './monitor-your-labour-costs.png';
import powerfulCommunicationToolsImage from './powerful-communication-tools.png';
import group121Image from './Group-121.png';
import group122Image from './Group-122.png';
import secureAndProtectOrangeImage from './secure-and-protect-orange-1.png';
import documentsFingertipsImage from './documents-fingertips-1-1.png';
import smartDocumentManagement from './smart-document-management.png';
import samePageImage from './same-page.png';
import workFromAnywhereImage from './work-from-anywhere.png';
import whatIsCloudBasedAccountingSoftwareImage from './what-is-cloud-based-accounting-software.png';
import lowerCostsImage from './lower-costs-2-1.png';
import growsWithYouImage from './grows-with-you-2-2.png';
import ourExperienceSetsUsApartImage from './our-experience-sets-us-apart-1.png';
import theWhatMakesUsDifferentImage from './the-what-makes-us-different.png';
import growYourBusiness from './grow-your-business.png';
// Leadspace
import leadspaceHomeImage from './leadspace-home-1.png';
import aboutUsLeadImage from './about-us-lead.png';
import constructionAccountsPayableImage from './construction-accounts-payable.png';
import constructionInvoicingImage from './leadspace-construction-invoicing.png';
import accountsPayableImage from './lead-accounts-payable.png';
import jobCostingImage from './job-costing-software.png';
import subcontractorsImage from './subcontractors-lead.png';
import documentManagementImage from './leadspace-document-management-1.png';
import cloudBasedAccountingSoftwareImage from './cloud-based-accounting-software.png';
import mobileTimesheetSoftwareImage from './mobile-timesheet-software.png';
import leadReportingAnalyticsImage from './lead-reporting-analytics.png';
import builtBetterImage from './built-better.png';
import generalLedgerCalloutImage from './general-ledger-callout.png';
import jobCostingCalloutImage from './job-costing-callout.png';
import jobCostingCalloutAImage from './job-costing-callout-a-1-1.png';
import mobileTimesheetCalloutImage from './mobile-timesheet-callout.png';
import subcontractorCalloutImage from './subcontractor-callout-2.png';
import documentMgmtCalloutImage from './document-mgmt-callout.png';
import reportingCalloutImage from './reporting-callout.png';
import floatingElement2Image from './floating-element-2.png';
import floatingElement3Image from './floating-element-3.png';
import floatingElement4Image from './floating-element-4.png';
import floatingElement5Image from './floating-element-5.png';
import floatingElement6Image from './floating-element-6.png';
import floatingElement7Image from './floating-element-7.png';
import floatingElement8Image from './floating-element-8.png';
import floatingElement9Image from './floating-element-9.png';
import floatingElement10Image from './floating-element-10.png';
import floatingElement11Image from './floating-element-11.png';
import floatingElement12Image from './floating-element-12.png';
import floatingElement13Image from './floating-element-13.png';
import floatingElement14Image from './floating-element-14.png';
import floatingElement15Image from './floating-element-15.png';
import floatingElement16Image from './floating-element-16.png';
import floatingElement17Image from './floating-element-17.png';
import floatingElement18Image from './floating-element-18.png';
import floatingElement20Image from './floating-element-20.png';
import floatingElement24Image from './floating-element-24.png';
import floatingElement25Image from './floating-element-25.png';
import floatingElement26Image from './floating-element-26.png';
import floatingElement1_1Image from './floating-element-1-1.png';
import floatingElement2_1Image from './floating-element-2-1.png';
import floatingElement3_1Image from './floating-element-3-1.png';
import floatingElement4_1Image from './floating-element-4-1.png';
import floatingElement5_1Image from './floating-element-5-1.png';
import floatingElement6_1Image from './floating-element-6-1.png';
import floatingElement7_1Image from './floating-element-7-1.png';
import floatingElement8_1Image from './floating-element-8-1.png';
import group132Image from './Group-132.png';
import group133Image from './Group-133.png';
import group134Image from './Group-134.png';
import accountsPayableCardImage from './card-accounts-payable.png';
import constructionInvoicingCardImage from './card-invoicing.png';
import jobCostingCardImage from './card-job-costing.png';
import subcontractorsCardImage from './card-subcontractors.png';
import documentManagementCardImage from './card-document-management-1.png';
import cloudBasedAccountingSoftwareCardImage from './card-cloud-accounting.png';
import mobileTimesheetSoftwareCardImage from './card-timesheet-software.png';
import leadReportingAnalyticsCardImage from './card-reporting.png';

export const imageMap = {
  // Leadspace
  'leadspace-home-1.png': leadspaceHomeImage,
  'about-us-lead.png': aboutUsLeadImage,
  'construction-accounts-payable.png': constructionAccountsPayableImage,
  'leadspace-construction-invoicing.png': constructionInvoicingImage,
  'lead-accounts-payable.png': accountsPayableImage,
  'job-costing-software.png': jobCostingImage,
  'subcontractors-lead.png': subcontractorsImage,
  'leadspace-document-management-1.png': documentManagementImage,
  'cloud-based-accounting-software.png': cloudBasedAccountingSoftwareImage,
  'mobile-timesheet-software.png': mobileTimesheetSoftwareImage,
  'lead-reporting-analytics.png': leadReportingAnalyticsImage,

  // Cards
  'card-accounts-payable.png': accountsPayableCardImage,
  'card-invoicing.png': constructionInvoicingCardImage,
  'card-job-costing.png': jobCostingCardImage,
  'card-subcontractors.png': subcontractorsCardImage,
  'card-document-management-1.png': documentManagementCardImage,
  'card-cloud-accounting.png': cloudBasedAccountingSoftwareCardImage,
  'card-timesheet-software.png': mobileTimesheetSoftwareCardImage,
  'card-reporting.png': leadReportingAnalyticsCardImage,

  // Icons
  'floating-element-2.png': floatingElement2Image,
  'floating-element-3.png': floatingElement3Image,
  'floating-element-4.png': floatingElement4Image,
  'floating-element-5.png': floatingElement5Image,
  'floating-element-6.png': floatingElement6Image,
  'floating-element-7.png': floatingElement7Image,
  'floating-element-8.png': floatingElement8Image,
  'floating-element-9.png': floatingElement9Image,
  'floating-element-10.png': floatingElement10Image,
  'floating-element-11.png': floatingElement11Image,
  'floating-element-12.png': floatingElement12Image,
  'floating-element-13.png': floatingElement13Image,
  'floating-element-14.png': floatingElement14Image,
  'floating-element-15.png': floatingElement15Image,
  'floating-element-16.png': floatingElement16Image,
  'floating-element-17.png': floatingElement17Image,
  'floating-element-18.png': floatingElement18Image,
  'floating-element-20.png': floatingElement20Image,
  'floating-element-24.png': floatingElement24Image,
  'floating-element-25.png': floatingElement25Image,
  'floating-element-26.png': floatingElement26Image,
  'floating-element-1-1.png': floatingElement1_1Image,
  'floating-element-2-1.png': floatingElement2_1Image,
  'floating-element-3-1.png': floatingElement3_1Image,
  'floating-element-4-1.png': floatingElement4_1Image,
  'floating-element-5-1.png': floatingElement5_1Image,
  'floating-element-6-1.png': floatingElement6_1Image,
  'floating-element-7-1.png': floatingElement7_1Image,
  'floating-element-8-1.png': floatingElement8_1Image,
  'Group-132.png': group132Image,
  'Group-133.png': group133Image,
  'Group-134.png': group134Image,

  // Callout
  'built-better.png': builtBetterImage,
  'general-ledger-callout.png': generalLedgerCalloutImage,
  'job-costing-callout.png': jobCostingCalloutImage,
  'job-costing-callout-a-1-1.png': jobCostingCalloutAImage,
  'subcontractor-callout-2.png': subcontractorCalloutImage,
  'document-mgmt-callout.png': documentMgmtCalloutImage,
  'mobile-timesheet-callout.png': mobileTimesheetCalloutImage,
  'reporting-callout.png': reportingCalloutImage,

  // Features
  'all-in-one_690-1.png': allInOneImage,
  'real-numbers_690-1.png': realNumbersImage,
  'peak-efficiency_690-1.png': peakEfficiencyImage,
  'automate-tedious-tasks.png': automateTediousTasksImage,
  'complete-control-of-your-construction-finance-1.png':
    completeControlOfYourConstructionBusinessImage,
  'keep-cash-flowing.png': keepCashFlowingImage,
  'boost-your-efficiency.png': boostYourEfficiencyImage,
  'stress-free-construction-invoicing.png':
    stressFreeConstructionInvoicingImage,
  'receivables-reporting-at-a-glance.png': receivablesReportingAtAGlanceImage,
  'optimize-your-efficiency.png': optimizeYourEfficiencyImage,
  'Group-124.png': group124Image,
  'flexible-accounts-payable.png': flexibleAccountsPayableImage,
  'data-you-can-rely-on.png': dataYouCanRelyOnImage,
  'subcontractor-callout-v2-1.png': subcontractorCalloutV2Image,
  'subcontractor-img.png': subcontractorImage,
  'subcontractor-accounting-simplified.png':
    subcontractorAccountingSimplifiedImage,
  'Group-120.png': group120Image,
  'eliminate-timesheet-uncertainty.png': eliminateTimesheetUncertaintyImage,
  'monitor-your-labour-costs.png': monitorYourLabourCostsImage,
  'powerful-communication-tools.png': powerfulCommunicationToolsImage,
  'Group-121.png': group121Image,
  'Group-122.png': group122Image,
  'secure-and-protect-orange-1.png': secureAndProtectOrangeImage,
  'documents-fingertips-1-1.png': documentsFingertipsImage,
  'smart-document-management.png': smartDocumentManagement,
  'same-page.png': samePageImage,
  'work-from-anywhere.png': workFromAnywhereImage,
  'what-is-cloud-based-accounting-software.png':
    whatIsCloudBasedAccountingSoftwareImage,
  'lower-costs-2-1.png': lowerCostsImage,
  'grows-with-you-2-2.png': growsWithYouImage,
  'our-experience-sets-us-apart-1.png': ourExperienceSetsUsApartImage,
  'the-what-makes-us-different.png': theWhatMakesUsDifferentImage,
  'grow-your-business.png': growYourBusiness
};
