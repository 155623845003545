import React from 'react';
import { imageMap } from '../Assets/imageAssets';
import defaultIcon from '../Assets/floating-element-2.png';
import './bulletList.scss';

export const safeRenderTextWithLinks = (descriptionArray) => {
  return (
    <>
      {descriptionArray.map((part, index) => {
        if (typeof part === 'string') {
          return <span key={index}>{part}</span>;
        } else if (part.type === 'line_break') {
          return <br />;
        } else if (part.type === 'link') {
          return (
            <a
              key={index}
              href={part.url}
              target='_blank'
              rel='noopener noreferrer'
              className='hover:text-orange-500 underline'
            >
              {part.text}
            </a>
          );
        } else if (part.type === 'bullet_list') {
          const bulletColorClass =
            part.color === 'blue'
              ? 'text-blue-500'
              : part.color === 'green'
              ? 'text-green-100'
              : 'text-orange-500';
          return (
            <ul
              key={`bullet-${index}`}
              className={`custom-bullet mt-6 md:mt-10 ${bulletColorClass}`}
            >
              {part.items.map((item, itemIndex) => (
                <li key={`bullet-${index}-${itemIndex}`}>
                  <span className='text-gray-600'>{item}</span>
                </li>
              ))}
            </ul>
          );
        } else if (part.type === 'icon_list') {
          return (
            <ul
              key={`icon-${index}`}
              className='icon-bullet mt-6 md:mt-10'
              style={{ listStyle: 'none' }}
            >
              {part.items.map(({ text, icon, alt }, itemIndex) => (
                <li
                  key={`icon-${index}-${itemIndex}`}
                  className='flex items-center'
                  style={itemIndex !== 0 ? { marginTop: '32px' } : {}}
                >
                  <img
                    src={imageMap[icon] || defaultIcon}
                    alt={alt}
                    className='mr-8'
                  />
                  <span className='text-gray-600'>{text}</span>
                </li>
              ))}
            </ul>
          );
        }
        return null;
      })}
    </>
  );
};
