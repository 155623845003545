import * as React from 'react';
import { Unstable_Grid2 as Grid } from '@mui/material';
import { Link } from '@mui/material';
import Item from '@mui/material/Grid';
import logo from '../Assets/Abio_Logo_NoTagline_White_1920w.png';
import data from '../data.json';

export default function BottomNav() {
  return (
    <Grid container className='bottom-nav-container bg-blue-700 text-white'>
      {/* Logo */}
      <Grid xs={11} sm={11} md={2} lg={2} xsOffset={1}>
        <Item component='a' href='/'>
          <img className='h-20 mb-12' src={logo} alt='Abio logo' />
        </Item>
      </Grid>

      {/* Contact Us */}
      <Grid xs={11} sm={11} md={3} lg={2} xsOffset={1} lgOffset={0}>
        <Item className='abio-footer-item mb-12'>
          <div className='font-bold mb-5'>Contact Us</div>
          <div>2632 Cavendish Ave</div>
          <div>Victoria, BC, Canada</div>
          <a
            className='hover:underline'
            href='mailto:marketing@abiosystems.ca?subject=Demo with Abio Systems'
            target='_blank'
            rel='noopener noreferrer'
          >
            marketing at abiosystems.ca
          </a>
        </Item>
      </Grid>

      {/* Features */}
      <Grid
        xs={11}
        sm={11}
        md={4}
        lg={3}
        xsOffset={1}
        mdOffset={1}
        lgOffset={1}
      >
        <Item className='abio-footer-item mb-12'>
          <div className='font-bold mb-5'>Features</div>
          {data.pages.features.map(({ title, href }) => {
            return (
              <div key={`nav-link-${href}`}>
                <Link
                  href={href}
                  color='white'
                  underline='none'
                  className='hover:underline'
                >
                  {title}
                </Link>
                <br />
              </div>
            );
          })}
        </Item>
      </Grid>

      {/* Information */}
      <Grid
        xs={11}
        sm={11}
        md={8}
        lg={2}
        xsOffset={1}
        mdOffset={4}
        lgOffset={0}
      >
        <Item className='abio-footer-item'>
          <div className='font-bold mb-5'>Information</div>
          <Link
            href='/privacy-policy'
            color='white'
            underline='none'
            className='hover:underline'
          >
            Privacy Policy
          </Link>
          <div>Copyright © Abio Systems Ltd. 2023</div>
          <div>All Rights Reserved.</div>
        </Item>
      </Grid>

      {/* Right gutter */}
      <Grid xs={0} sm={0} md={0} lg={1} />
    </Grid>
  );
}
