import { Unstable_Grid2 as Grid } from '@mui/material';
import Item from '@mui/material/Grid';
import builtBetterImage from '../Assets/built-better.png';

const InlineCalloutRight = ({
  text,
  imgSrc,
  alt,
  fontSize = 'large',
  theme = 'dark'
}) => {
  const fontSizeClass =
    fontSize === 'small' ? 'abio-title' : 'abio-feature-title';
  const colorClasses =
    theme === 'dark' ? 'bg-slate text-white' : 'bg-white text-gray-700';

  return (
    <div className='inline-callout-right-container'>
      <Grid
        container
        className={colorClasses}
        style={{ flexWrap: 'wrap-reverse' }} // so that text and image order match feature left component
      >
        <Grid
          xs={10}
          sm={10}
          md={4}
          lg={3}
          xl={3}
          xsOffset={1}
          className='flex'
        >
          <img
            src={imgSrc || builtBetterImage}
            alt={alt}
            className='w-full object-contain'
          />
        </Grid>
        <Grid
          xs={10}
          sm={10}
          md={5}
          lg={6}
          xsOffset={1}
          className='flex items-center'
        >
          <Item>
            <p className={`${fontSizeClass}`}>{text}</p>
          </Item>
        </Grid>
      </Grid>
    </div>
  );
};

export default InlineCalloutRight;
