import { useState } from 'react';
import Link from '@mui/material/Link';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

const LearnMore = ({ href, colorMode = 'green' }) => {
  const [isHovered, setHovered] = useState(false);

  const toggleHover = () => setHovered(!isHovered);

  let baseColor, shadowColor;

  if (colorMode === 'green') {
    baseColor = isHovered ? 'rgba(0, 183, 122, 1)' : 'rgba(1, 131, 87, 1)'; // light green / dark green
    shadowColor = isHovered
      ? 'rgba(0, 183, 122, 0.25)'
      : 'rgba(76, 81, 101, 0.125)'; // light green / black
  } else if (colorMode === 'blue') {
    baseColor = isHovered ? 'rgba(76, 102, 244, 1)' : 'rgba(113, 136, 255, 1)'; // light blue / dark blue
    shadowColor = isHovered
      ? 'rgba(76, 102, 244, 0.25)'
      : 'rgba(113, 136, 255, 0.125)'; // light blue / dark blue
  }

  return (
    <div className='abio-link-container'>
      <Link
        underline='none'
        href={href}
        className='flex items-center'
        onMouseEnter={toggleHover}
        onMouseLeave={toggleHover}
      >
        <div
          className='abio-link'
          style={{
            color: baseColor
          }}
        >
          Learn more
        </div>
        <div
          className='bg-white flex items-center justify-center w-12 h-12 rounded-full ml-3'
          style={{
            filter: `drop-shadow(${shadowColor} 0px 0px 8px)`
          }}
        >
          <KeyboardArrowRightIcon
            fontSize='medium'
            style={{ color: baseColor }}
          />
        </div>
      </Link>
    </div>
  );
};

export default LearnMore;
