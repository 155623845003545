import { Unstable_Grid2 as Grid } from '@mui/material';
import Item from '@mui/material/Grid';
import LearnMore from './LearnMore';
import placeholder2 from '../Assets/all-in-one_690-1.png';
import { safeRenderTextWithLinks } from '../utils/safeRenderTextWithLinks';

const FeatureLeft = ({ headline, description, href, imgSrc, alt }) => {
  return (
    <div className='feature-container'>
      <Grid container className='bg-white'>
        <Grid
          xs={10}
          sm={5}
          md={5}
          lg={5}
          xsOffset={1}
          className='flex items-center'
        >
          <Item>
            {headline && (
              <h2 className='abio-feature-title text-gray-800'>{headline}</h2>
            )}
            <p className='abio-paragraph text-gray-600'>
              {safeRenderTextWithLinks(description)}
            </p>
            {href && <LearnMore href={href} />}
          </Item>
        </Grid>
        <Grid xs={10} sm={4} md={4} lg={4} xsOffset={1} className='flex'>
          <img
            src={imgSrc || placeholder2}
            alt={alt}
            className='feature-image w-full object-contain'
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default FeatureLeft;
