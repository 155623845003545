import GridOverlay from './Components/GridOverlay';
import Header from './Components/Header.jsx';
import Leadspace from './Components/Leadspace.jsx';
import FeatureLeft from './Components/FeatureLeft.jsx';
import FeatureLeft2 from './Components/FeatureLeft2.jsx';
import FeatureRight from './Components/FeatureRight.jsx';
import Callout from './Components/Callout.jsx';
import InlineCallout from './Components/InlineCallout.jsx';
import InlineCallout2 from './Components/InlineCallout2.jsx';
import InlineCalloutRight from './Components/InlineCalloutRight.jsx';
import IconList from './Components/IconList.jsx';
import Carousel from './Components/Carousel.jsx';
import ContactForm from './Components/ContactForm.jsx';
import BottomNav from './Components/BottomNav.jsx';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { imageMap } from './Assets/imageAssets';
import data from './data.json';
import './styles/fontStyles.scss';
import './styles/spacingStyles.scss';

const componentMap = {
  Leadspace: Leadspace,
  FeatureLeft: FeatureLeft,
  FeatureLeft2: FeatureLeft2,
  FeatureRight: FeatureRight,
  Callout: Callout,
  InlineCallout: InlineCallout,
  InlineCallout2: InlineCallout2,
  InlineCalloutRight: InlineCalloutRight,
  IconList: IconList,
  Carousel: Carousel,
  ContactForm: ContactForm
};

const generatePage = ({ href, title, layout }) => {
  return (
    <Route
      key={href}
      path={href}
      element={
        <>
          <Helmet>
            <title>{title} | Abio</title>
          </Helmet>
          {layout.map(({ component, props }, index) => {
            const componentProps = {
              ...props,
              imgSrc: imageMap[props?.imgSrc]
            };

            const ComponentToRender = componentMap[component];
            return (
              <ComponentToRender
                key={`${component}-${index}`}
                {...componentProps}
              />
            );
          })}
        </>
      }
    />
  );
};

const App = () => {
  // Avoid destructuring data.pages here to prevent build errors
  const pages = [...data.pages.features, ...data.pages.core].map(generatePage);

  return (
    <Router>
      <div className='font-red-hat-display'>
        {process.env.NODE_ENV !== 'production' && <GridOverlay />}
        <Header />
        <Routes>{pages}</Routes>
        <BottomNav />
      </div>
    </Router>
  );
};

export default App;
