import React, { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { Unstable_Grid2 as Grid } from '@mui/material';
import BookADemoButton from './BookADemo';
import bookADemo from '../Assets/book-a-demo.png';
import './loadingSpinner.scss';

const ContactForm = () => {
  const theme = useTheme();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const [formData, setFormData] = useState({
    fullName: '',
    companyName: '',
    workEmail: '',
    phoneNumber: ''
  });
  const [submitStatus, setSubmitStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true); // Set to true as we start the request
    fetch('/send-email', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(formData)
    })
      .then((response) => response.text())
      .then((text) => {
        setSubmitStatus('success');
        setIsLoading(false); // Set to false once the request is successful
      })
      .catch((error) => {
        console.error('There was a problem with sending the email:', error);
        setSubmitStatus('failure');
        setIsLoading(false); // Set to false if the request has failed
      });
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className='min-h-screen-80 flex items-center justify-center'>
          <div className='loader' />;
        </div>
      );
    }
    if (submitStatus === 'success') {
      return (
        <div className='min-h-screen-80 flex items-center justify-center text-center md:justify-start md:text-left'>
          <div className='text-gray-700 my-12'>
            <h1 className='abio-feature-title'>
              Thank you for your interest in Abio!
            </h1>
            <p className='abio-contact-paragraph'>
              We've received your request and will be in touch within the next
              three business days to schedule your demo.
            </p>
          </div>
        </div>
      );
    }
    if (submitStatus === 'failure') {
      return (
        <div className='min-h-screen-80 flex items-center justify-center text-center md:justify-start md:text-left'>
          <div className='text-gray-700 my-12'>
            <h1 className='abio-feature-title'>
              Oops!
              <br />
              Something went wrong.
            </h1>
            <p className='abio-contact-paragraph'>
              Feel free to send us an email at{' '}
              <a
                className='underline'
                href='mailto:marketing@abiosystems.ca?subject=Demo with Abio Systems'
                target='_blank'
                rel='noopener noreferrer'
              >
                marketing@abiosystems.ca
              </a>{' '}
              to book a demo.
            </p>
          </div>
        </div>
      );
    }
    // If submitStatus is null, render the form
    return (
      <div className='md:min-h-screen-80 md:flex md:items-center'>
        <form onSubmit={handleSubmit} className='my-12'>
          <label htmlFor='fullName' className='abio-form-label text-gray-700'>
            Full Name
          </label>
          <input
            type='text'
            name='fullName'
            placeholder='First and Last Name'
            className='abio-form-label w-full rounded bg-blue-200 h-16 p-2 pl-5'
            value={formData.fullName}
            onChange={handleChange}
          />

          <label
            htmlFor='companyName'
            className='abio-form-label text-gray-700'
          >
            Company Name
          </label>
          <input
            type='text'
            name='companyName'
            placeholder='Company Name'
            className='abio-form-label w-full rounded bg-blue-200 h-16 p-2 pl-5'
            value={formData.companyName}
            onChange={handleChange}
          />
          <label htmlFor='workEmail' className='abio-form-label text-gray-700'>
            Work Email <span className='text-red'>*</span>
          </label>
          <input
            type='email'
            name='workEmail'
            placeholder='name@work-email.com'
            className='abio-form-label w-full rounded bg-blue-200 h-16 p-2 pl-5'
            value={formData.workEmail}
            onChange={handleChange}
            required
          />
          <label
            htmlFor='phoneNumber'
            className='abio-form-label text-gray-700'
          >
            Phone Number
          </label>
          <input
            type='tel'
            name='phoneNumber'
            placeholder='+1'
            className='abio-form-label w-full rounded bg-blue-200 h-16 p-2 pl-5'
            value={formData.phoneNumber}
            onChange={handleChange}
          />
          <div className='mt-4'>
            <BookADemoButton type='submit' variant='no-icon' color='green' />
          </div>
        </form>
      </div>
    );
  };

  return (
    <Grid container>
      {/* Navy background */}
      <Grid xs={12} md={6} className='bg-blue-700'>
        <Grid
          xs={10}
          sm={10}
          md={11}
          lg={11}
          style={{
            marginLeft: isMdUp ? 'calc(200%/12)' : 'calc(100%/12)',
            marginRight: isMdUp ? 'calc(200%/12)' : 'calc(100%/12)'
          }} // manually add offset
        >
          <div className='text-white contact-form-text-container'>
            <h1 className='abio-feature-title'>Book a demo</h1>
            <p className='abio-contact-paragraph'>
              No matter the scale of your business, Abio's construction
              accounting software provides you with the essential tools to
              steadily grow. Get started by booking a demo with us today.
            </p>
          </div>
        </Grid>
        <Grid xs={10} sm={10} md={6} lg={6}>
          <img
            src={bookADemo}
            alt="A construction worker with a clipboard, overlaid with callouts for 'Phone call' and 'Video walk-through', symbolizing Abio's offer to demo the construction accounting software."
            className='w-full'
          />
        </Grid>
      </Grid>

      {/* White background */}
      <Grid xs={12} md={6} id='contact-form-section' className='bg-white'>
        <Grid
          xs={10} // 10/12 columns
          md={12} // full width of container
          style={{
            marginLeft: isMdUp ? 'calc(200%/12)' : 'calc(100%/12)',
            marginRight: isMdUp ? 'calc(200%/12)' : 'calc(100%/12)'
          }} // manually add offset
        >
          {renderContent()}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactForm;
